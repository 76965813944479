import { carRule, bikeRule, busRule, pedestrianRule } from "./filterRules"
import { generateDateScoreFilterFromString } from "./helper"
import { op_car_day, op_car_day_agg, op_car_hour, op_car_month, op_car_month_agg, op_car_week, op_car_week_agg, op_car_year, op_car_year_agg } from "../Services/serviceLayers"

export function zoomRules(mapContext, MapValue, zoomVisualFlag) {
    //console.log(zoomVisualFlag)
    let zoomState = zoomVisualFlag
    if (MapValue === 2) {
        if (mapContext.state.compareTask === 3) {
            //console.log("EXIT")
            for (let i = 0; i < mapContext.state.mapView.layerViews.items.length; i++){
                if (mapContext.state.mapView.layerViews.items[i].layer.visible === true) {
                    mapContext.state.mapView.layerViews.items[i].filter = null 
                }
            }
            return
        }
    }
    if (mapContext.state.mapView.zoom < 12 && (MapValue === 0 || MapValue === 1 || MapValue === 2) &&  zoomVisualFlag !== 3) {
        zoomState = 3
            if(MapValue === 0 && (mapContext.state.data.toLowerCase() === 'strava'))
            {
              for (let i = 0; i < mapContext.state.mapView.layerViews.items.length; i++){
                  if (mapContext.state.mapView.layerViews.items[i].layer.visible === true) {
                    let whereClause
                  if (mapContext.state.data.toLowerCase() === 'strava') {
                    whereClause = 'sum_total'
                  } else {
                    whereClause = 'usage'
                  }
                  let str =  whereClause + ' >= ' + Math.floor((mapContext.state.maxValue * 0.3) + 1)
                  //console.log(mapContext.state.mapView.layerViews.items[i])
                  //console.log( str)
                  
                  mapContext.state.mapView.layerViews.items[i].filter = {
                    where: str
                  };
                  mapContext.state.featureLayer.renderer.visualVariables[0].maxSize = "5px";
                  //console.log("zoom 1")
                }
              }
            } else if (MapValue === 0 && (mapContext.state.data.toLowerCase() === 'SISP'))
            {
              return
            } 
            else if (mapContext.state.transportation.toLowerCase() === "car")
            {
              for (let i = 0; i < mapContext.state.mapView.layerViews.items.length; i++){
                if (mapContext.state.mapView.layerViews.items[i].layer.visible === true) {
                  let baseFilter = "highway IN ('motorway', 'primary', 'trunk')"
    
                  if (mapContext.state.dateRange !== null) {
                    let str = generateDateScoreFilterFromString(mapContext.state.dateRange);
                    mapContext.state.mapView.layerViews.items[i].filter = {
                      where: baseFilter + str
                    };
                  } else {
                    mapContext.state.mapView.layerViews.items[i].filter = {
                      where: baseFilter 
                    };
                  }
    
                  //console.log("Filter OSM TEST")
                  //console.log(mapContext.state.mapView.layerViews.items[i].filter.where)
                  //console.log("zoom 1")
                }
              }
            }
        }
    
        if (mapContext.state.mapView.zoom >= 12 && mapContext.state.mapView.zoom < 13 && (MapValue === 0 || MapValue === 1 || MapValue === 2) &&  zoomVisualFlag !== 2) {
        zoomState = 2
          if(MapValue === 0 && (mapContext.state.data.toLowerCase() === 'strava'))
          {
            //sum_total - strava
            //usage - op
            for (let i = 0; i < mapContext.state.mapView.layerViews.items.length; i++){
              if (mapContext.state.mapView.layerViews.items[i].layer.visible === true) {
                let whereClause
                if (mapContext.state.data.toLowerCase() === 'strava') {
                  whereClause = 'sum_total'
                } else {
                  whereClause = 'usage'
                }
                let str =  whereClause + ' >= ' + Math.floor((mapContext.state.maxValue * 0.3) + 1)
                //console.log(mapContext.state.mapView.layerViews.items[i])
                //console.log( str)
                mapContext.state.mapView.layerViews.items[i].filter = {
                  where: str
                };
                //mapContext.state.featureLayer.definitionExpression = str
                mapContext.state.featureLayer.renderer.visualVariables[0].maxSize = "5px";
                //console.log("zoom 2")
              }
            }
          } else if (MapValue === 0 && (mapContext.state.data.toLowerCase() === 'SISP'))
            {
              return
            } 
          else 
            if  (mapContext.state.transportation.toLowerCase() === "bike" || mapContext.state.transportation.toLowerCase() === "pedestrian"){
              if (mapContext.state.breaks.length == null|| mapContext.state.breaks == undefined){
                return
              }
              let usageBreakpoint 
              let breakLength = mapContext.state.breaks.length
              if (breakLength < 100) {
                usageBreakpoint = Math.floor(breakLength/100 * 85)
              } else {
                usageBreakpoint = 85
              }
              let usageAmounbt = mapContext.state.breaks[usageBreakpoint].minValue
              let whereClause
              if (mapContext.state.data.toLowerCase() === 'strava') {
                whereClause = 'sum_total'
              } else {
                whereClause = 'usage'
              }
              for (let i = 0; i < mapContext.state.mapView.layerViews.items.length; i++){
                if (mapContext.state.mapView.layerViews.items[i].layer.visible === true) {
                  if (mapContext.state.mapView.layerViews.items[i].layer.visible === true) {
                    let baseFilter = whereClause + " >= " + usageAmounbt
                    mapContext.state.mapView.layerViews.items[i].filter = {
                      where: baseFilter
                    };
                    console.log(mapContext.state.mapView.layerViews.items[i].filter.where)
                  }
                }             
            }
                 
          }  
        else if (mapContext.state.transportation.toLowerCase() === "car")
            {
            for (let i = 0; i < mapContext.state.mapView.layerViews.items.length; i++){
              if (mapContext.state.mapView.layerViews.items[i].layer.visible === true ) {
                
                let baseFilter = "highway IN ('motorway', 'motorway_link', 'primary', 'primary_link', 'trunk', 'trunk_link', 'secondary', 'secondary_link', 'tertiary', 'tertiary_link', 'unclassified')"
    
                if (mapContext.state.dateRange !== null) {
                  let str = generateDateScoreFilterFromString(mapContext.state.dateRange);
                  mapContext.state.mapView.layerViews.items[i].filter = {
                    where: baseFilter + str
                  };
                } else {
                  mapContext.state.mapView.layerViews.items[i].filter = {
                    where: baseFilter 
                  };
                }
    
                //console.log("Filter OSM TEST")
                //console.log(mapContext.state.mapView.layerViews.items[i].filter.where)
    
    
    
                //console.log("TestDateRange")
                
    
                
                //console.log("zoom 2")
                //console.log(mapContext.state.mapView.layerViews)
              }
            }
          }
        }
    
        if (mapContext.state.mapView.zoom >= 13 && mapContext.state.mapView.zoom < 14 && (MapValue === 0 || MapValue === 1 || MapValue === 2) && zoomVisualFlag !== 1) {
            zoomState = 1
          
          if(MapValue === 0 && (mapContext.state.data.toLowerCase() === 'strava'))
          {
            for (let i = 0; i < mapContext.state.mapView.layerViews.items.length; i++){
              if (mapContext.state.mapView.layerViews.items[i].layer.visible === true) {
                if (mapContext.state.mapView.layerViews.items[i].layer.visible === true) {
                  mapContext.state.mapView.layerViews.items[i].filter = null;
                  mapContext.state.featureLayer.renderer.visualVariables[0].maxSize = "5px";
                  console.log("zoom 3")
                }
              }
            }
    
          } 
        else if (MapValue === 0 && (mapContext.state.data.toLowerCase() === 'SISP'))
            {
              return
            }
            else 
            if  (mapContext.state.transportation.toLowerCase() === "bike" || mapContext.state.transportation.toLowerCase() === "pedestrian"){
              if (mapContext.state.breaks.length == null|| mapContext.state.breaks == undefined){
                return
              }
              let usageBreakpoint 
              let breakLength = mapContext.state.breaks.length
              if (breakLength < 100) {
                usageBreakpoint = Math.floor(breakLength/100 * 35)
              } else {
                usageBreakpoint = 35
              }
              let usageAmounbt = mapContext.state.breaks[usageBreakpoint].minValue
              let whereClause
              if (mapContext.state.data.toLowerCase() === 'strava') {
                whereClause = 'sum_total'
              } else {
                whereClause = 'usage'
              }
              for (let i = 0; i < mapContext.state.mapView.layerViews.items.length; i++){
                if (mapContext.state.mapView.layerViews.items[i].layer.visible === true) {
                  if (mapContext.state.mapView.layerViews.items[i].layer.visible === true) {
                    let baseFilter = whereClause + " >= " + usageAmounbt
                    mapContext.state.mapView.layerViews.items[i].filter = {
                      where: baseFilter
                    };
                    console.log(mapContext.state.mapView.layerViews.items[i].filter.where)
                  }
                }             
            }
                 
          }  
        else if (mapContext.state.transportation.toLowerCase() === "car")
            {
            for (let i = 0; i < mapContext.state.mapView.layerViews.items.length; i++){
              if (mapContext.state.mapView.layerViews.items[i].layer.visible === true) {
    
                let baseFilter = "highway IN ('motorway', 'motorway_link', 'primary', 'primary_link', 'trunk', 'trunk_link', 'secondary', 'secondary_link', 'tertiary', 'tertiary_link', 'unclassified', 'living_street', 'motorway_junction', 'residential')"
    
                if (mapContext.state.dateRange !== null) {
                  let str = generateDateScoreFilterFromString(mapContext.state.dateRange);
                  mapContext.state.mapView.layerViews.items[i].filter = {
                    where: baseFilter + str
                  };
                } else {
                  mapContext.state.mapView.layerViews.items[i].filter = {
                    where: baseFilter 
                  };
                }
    
                console.log("Filter OSM TEST")
                console.log(mapContext.state.mapView.layerViews.items[i].filter.where)
                console.log("zoom 3")
              }
            }
          }    
        }
    
        if ((mapContext.state.mapView.zoom >= 14 || MapValue === 3 )&& zoomVisualFlag !== 0) {
          
            zoomState = 0
            
            
            if(MapValue === 0 && (mapContext.state.data.toLowerCase() === 'strava'))
            {
              for (let i = 0; i < mapContext.state.mapView.layerViews.items.length; i++){
                if (mapContext.state.mapView.layerViews.items[i].layer.visible === true) {
                  if (mapContext.state.mapView.layerViews.items[i].layer.visible === true) {
                    mapContext.state.mapView.layerViews.items[i].filter = null;
                    mapContext.state.featureLayer.renderer.visualVariables[0].maxSize = "5px";
                    console.log("zoom 4")
                  }
                }
              }
            } 
            else if (MapValue === 0 && (mapContext.state.data.toLowerCase() === 'SISP'))
              {
                return
              } else 
              if  (mapContext.state.transportation.toLowerCase() === "bike" || mapContext.state.transportation.toLowerCase() === "pedestrian"){
                if (mapContext.state.breaks.length == null|| mapContext.state.breaks == undefined){
                  return
                }
                let whereClause
                if (mapContext.state.data.toLowerCase() === 'strava') {
                  whereClause = 'sum_total'
                } else {
                  whereClause = 'usage'
                }
                for (let i = 0; i < mapContext.state.mapView.layerViews.items.length; i++){
                  if (mapContext.state.mapView.layerViews.items[i].layer.visible === true) {
                    if (mapContext.state.mapView.layerViews.items[i].layer.visible === true) {
                      let baseFilter = whereClause + " >= " + 0
                      mapContext.state.mapView.layerViews.items[i].filter = {
                        where: baseFilter
                      };
                      console.log(mapContext.state.mapView.layerViews.items[i].filter.where)
                    }
                  }             
              }
                   
            } 
              else if (mapContext.state.transportation.toLowerCase() === "car")
                 {
              for (let i = 0; i < mapContext.state.mapView.layerViews.items.length; i++){
                if (mapContext.state.mapView.layerViews.items[i].layer.visible === true ) {
                  mapContext.state.mapView.layerViews.items[i].filter = null
    
    
                  let baseFilter = "highway IN ('motorway', 'motorway_link', 'primary', 'primary_link', 'trunk', 'trunk_link', 'secondary', 'secondary_link', 'tertiary', 'tertiary_link', 'unclassified', 'living_street', 'residential', 'motorway_junction', 'service')"
    
                  //let baseFilter = null
                  if (mapContext.state.dateRange !== null) {
                    let str = generateDateScoreFilterFromString(mapContext.state.dateRange);
                    mapContext.state.mapView.layerViews.items[i].filter = {
                      where: baseFilter + str
                    };
                  } else {
                    mapContext.state.mapView.layerViews.items[i].filter = {
                      where: baseFilter 
                    };
                  }
    
                  console.log("Filter OSM TEST")
                  console.log(mapContext.state.mapView.layerViews.items[i].filter.where)
    
    
                  console.log("zoom 4")
                  /*console.log( "mapContext.state.mapView.layerViews.items[i].filter")*/
                  console.log( mapContext.state.mapView.layerViews.items[i])
                }
              }
          }
          }

    return zoomState
}

export function targetCarZoomAgg(mapContext) {
    console.log("TIME AGG ", mapContext.state.time)
    if (mapContext.state.time.toLowerCase() === "day") {
        if (mapContext.state.mapView.zoom < 13) {
            return op_car_day_agg
        } else {
            return op_car_day
        }
    }
    if (mapContext.state.time.toLowerCase() === "week") {
        if (mapContext.state.mapView.zoom < 13) {
            return op_car_week_agg
        } else {
            return op_car_week
        }
    }
    if (mapContext.state.time.toLowerCase() === "month") {
        if (mapContext.state.mapView.zoom < 13) {
            return op_car_month_agg
        } else {
            return op_car_month
        }
    }
    if (mapContext.state.time.toLowerCase() === "year") {
        if (mapContext.state.mapView.zoom < 13) {
            return op_car_year_agg
        } else {
            return op_car_year
        }
    }
}