import UniqueValueRenderer from "@arcgis/core/renderers/UniqueValueRenderer.js";

export const popUpStrava = {
  title: "Strava duomenys",
  content: "<b> suma pravažiuojančių mašinų:<b/> {sum_total} <br> <b>menuo:<b/> {month} <br> <b> data: <b/> {date}",
};
export const popUpWaze = {
  title: "Waze Duomenys",
  content: "<b> Kelio ilgis:<b/> {length}<br> <b> timestamp: <b/> {timestamp}<br> <b>baigties laikas: <b/> {end_time}  ",
};
export const popUpOperator = {
  title: "Operatorių duomenys",
  content: "<b> naudojimas:<b/> {usage}<br> <b> matavimo laikas: <b/> {datetime}",
};

export const trailsRendererWaze = {
  type: "simple",
  symbol: {
    color: "#BA55D3",
    type: "simple-line",
    style: "solid",
  },
  visualVariables: [
    {
      type: "size",
      field: "sum_total",
      minDataValue: 0,
      maxDataValue: 2300,
      minSize: "1px",
      maxSize: "5px",
    },
  ],
};
export const trailsRendererStravaBikes = {
  type: "simple",
  symbol: {
    color: "#AA7053",
    type: "simple-line",
    style: "solid",
  },

  visualVariables: [
    {
      type: "size",
      field: "sum_total",
      minDataValue: 0,
      maxDataValue: 2300,
      minSize: "1px",
      maxSize: "5px",
    },
  ],
};

export const operatorRenderer = {
  type: "simple",
  symbol: {
    color: "#2C8C4B",
    type: "simple-line",
    style: "solid",
  },

  visualVariables: [
    {
      type: "size",
      field: "usage",
      minDataValue: 0,
      maxDataValue: 500,
      minSize: "1px",
      maxSize: "5px",
    },
    {
      type: "color",
      field: "usage",
      stops: [
        { value: 0, color: "#FFFFb2" },
        { value: 50, color: "#fecc5c" },
        { value: 100, color: "#fd8d3c" },
        { value: 400, color: "#f03b20" },
        { value: 800, color: "#bd0026" },
      ],
    },
  ],
};

export const trailsRendererStravaPedestriansStatic = () => {

  return {
    type: "simple",
    symbol: {
      color: "#FFFFb2",
      type: "simple-line",
      style: "solid",
    }
  };
};

export const trailsRendererStravaPedestrians = (maxValue) => {
  let localMax;
  if (maxValue == null) {
    localMax = 2300;
  } else {
    localMax = maxValue;
  }
  return {
    type: "simple",
    symbol: {
      color: "#2C8C4B",
      type: "simple-line",
      style: "solid",
    },

    visualVariables: [
      {
        type: "size",
        field: "sum_total",
        minDataValue: 0,
        maxDataValue: localMax,
        minSize: "1px",
        maxSize: "7px",
      },
      {
        type: "color",
        field: "sum_total",
        stops: [
          { value: 0, color: "#FFFFb2" },
          { value: localMax * 0.1, color: "#fecc5c" },
          { value: localMax * 0.3, color: "#fd8d3c" },
          { value: localMax * 0.6, color: "#f03b20" },
          { value: localMax * 0.9, color: "#bd0026" },
        ],
      },
      {
        type: "opacity",
        field: "TotalFarmedAcres",
        normalizationField: "sum_total",
        legendOptions: {
          showLegend: false,
        },
        stops: [
          { value: 50, opacity: 0.0 },
          { value: localMax, opacity: 0.8 },
        ],
      },
    ],
  };
};

export const trailsRendererOperatorDynamic = (maxValue) => {
  let localMax;
  if (maxValue == null) {
    localMax = 2300;
  } else {
    localMax = maxValue;
  }
  return {
    type: "simple",
    symbol: {
      color: "#2C8C4B",
      type: "simple-line",
      style: "solid",
    },

    visualVariables: [
      {
        type: "size",
        field: "usage",
        minDataValue: 0,
        maxDataValue: localMax,
        minSize: "1px",
        maxSize: "7px",
      },
      {
        type: "color",
        field: "usage",
        stops: [
          { value: 0, color: "#FFFFb2" },
          { value: localMax * 0.1, color: "#fecc5c" },
          { value: localMax * 0.3, color: "#fd8d3c" },
          { value: localMax * 0.6, color: "#f03b20" },
          { value: localMax * 0.9, color: "#bd0026" },
        ],
      },
      {
        type: "opacity",
        field: "TotalFarmedAcres",
        normalizationField: "sum_total",
        legendOptions: {
          showLegend: false,
        },
        stops: [
          { value: 50, opacity: 0.0 },
          { value: localMax, opacity: 0.8 },
        ],
      },
    ],
  };
};

export const trailsRendererOperatorNonCarDynamic = (breaks) => {
  let localMax;
  console.log("Breaks:", breaks);
  if (breaks === undefined) {
    return
    localMax = 2300;
  }
  return {
    type: "simple",
    symbol: {
      color: "#2C8C4B",
      type: "simple-line",
      style: "solid",
    },

    visualVariables: [
      {
        type: "size",
        field: "usage",
        minDataValue: breaks[0].minValue,
        maxDataValue: breaks[95].minValue,
        minSize: "1px",
        maxSize: "7px",
      },
      {
        type: "color",
        field: "usage",
        stops: [
          
          { value: breaks[0].minValue, color: "#FFFFb2" },
          { value: breaks[35].minValue, color: "#fecc5c" },
          { value: breaks[85].minValue, color: "#fd8d3c" },
          { value: breaks[90].minValue, color: "#f03b20" },
          { value: breaks[95].minValue, color: "#bd0026" },
        ],
      },
      {
        type: "opacity",
        field: "TotalFarmedAcres",
        normalizationField: "sum_total",
        legendOptions: {
          showLegend: false,
        },
        stops: [
          { value: 50, opacity: 0.0 },
          { value: localMax, opacity: 0.8 },
        ],
      },
    ],
  };
};

export const trailsRendererStravaNonCarDynamic = (breaks) => {
  let localMax;
  console.log("Breaks:", breaks);
  let breakpoints = [0, 35, 85, 90, 99] 
  if (breaks === undefined) {
    return
    localMax = 2300;
  }
  if (breaks.length < 100) {
    breakpoints = [0, Math.floor(breaks.length/100 * 35), Math.floor(breaks.length/100 * 85), Math.floor(breaks.length/100 * 90), Math.floor(breaks.length/100 * 99)]
    console.log("Breaks:", breakpoints)
  }

  return {
    type: "simple",
    symbol: {
      color: "#2C8C4B",
      type: "simple-line",
      style: "solid",
    },

    visualVariables: [
      {
        type: "size",
        field: "sum_total",
        minDataValue: breaks[0].minValue,
        maxDataValue: breaks[breakpoints[4]].minValue,
        minSize: "1px",
        maxSize: "7px",
      },
      {
        type: "color",
        field: "sum_total",
        stops: [
          { value: breaks[breakpoints[0]].minValue, color: "#FFFFb2" },
          { value: breaks[breakpoints[1]].minValue, color: "#fecc5c" },
          { value: breaks[breakpoints[2]].minValue, color: "#fd8d3c" },
          { value: breaks[breakpoints[3]].minValue, color: "#f03b20" },
          { value: breaks[breakpoints[4]].minValue, color: "#bd0026" },
        ],
      },
      {
        type: "opacity",
        field: "TotalFarmedAcres",
        normalizationField: "sum_total",
        legendOptions: {
          showLegend: false,
        },
        stops: [
          { value: 50, opacity: 0.0 },
          { value: localMax, opacity: 0.8 },
        ],
      },
    ],
  };
};

export const dynamicRenderOperator = (maxValue) => {
  let localMax;
  if (maxValue == null) {
    localMax = 2300;
  } else {
    localMax = maxValue;
  }
  return {
    type: "simple",
    symbol: {
      color: "#2C8C4B",
      type: "simple-line",
      style: "solid",
    },

    visualVariables: [
      {
        type: "size",
        field: "usage",
        minDataValue: 0,
        maxDataValue: localMax,
        minSize: "1px",
        maxSize: "7px",
      },
      {
        type: "color",
        field: "usage",
        stops: [
          { value: 0, color: "#FFFFb2" },
          { value: localMax * 0.1, color: "#fecc5c" },
          { value: localMax * 0.3, color: "#fd8d3c" },
          { value: localMax * 0.6, color: "#f03b20" },
          { value: localMax * 0.9, color: "#bd0026" },
        ],
      },
      {
        type: "opacity",
        field: "TotalFarmedAcres",
        normalizationField: "usage",
        legendOptions: {
          showLegend: false,
        },
        stops: [
          { value: 50, opacity: 0.0 },
          { value: localMax, opacity: 0.8 },
        ],
      },
    ],
  };
};

export const trailsRendererStravaBikesDynamic = (maxValue) => {
  let localMax;
  if (maxValue == null) {
    localMax = 2300;
  } else {
    localMax = maxValue;
  }
  return {
    type: "simple",
    symbol: {
      color: "#AA7053",
      type: "simple-line",
      style: "solid",
    },
  
    visualVariables: [
      {
        type: "size",
        field: "sum_total",
        minDataValue: 0,
        maxDataValue: localMax,
        minSize: "1px",
        maxSize: "7px",
      },
      {
        type: "color",
        field: "sum_total",
        stops: [
          { value: 0, color: "#ad8858" },
          { value: localMax * 0.1, color: "#AA7053" },
          { value: localMax * 0.3, color: "#bb6c44" },
          { value: localMax * 0.6, color: "#ab5b54" },
          { value: localMax * 0.9, color: "#b5534a" },
        ],
      },
      {
        type: "opacity",
        field: "TotalFarmedAcres",
        normalizationField: "sum_total",
        legendOptions: {
          showLegend: false,
        },
        stops: [
          { value: 50, opacity: 0.0 },
          { value: localMax, opacity: 0.8 },
        ],
      },
    ],
  }
};

const analyticsType1 = (w) => {
  return {
  type: "simple-line",
  color: [232,76,49, 1],
  width: w,
  
}}

const analyticsType2 = (w) => { 
  return{
  type: "simple-line",
  color: [232,76,49, 1],
  width: w,

}}


const analyticsType3 = (w) => {
  return{
  type: "simple-line",
  color: [239,138,72,1],
  width: w,
}}


const analyticsType4 = (w) => {
  return {
  type: "simple-line",
  color: [	239,138,72,0.4],
  width: w,
}
}

const analyticsTypeBike = (w) => {
  return {
    type: "simple-line",
    //color: "green",
    color: [	255, 174, 55, 0.4],
    width: w,
  }
}

export const analyticsOutline = {
  type: "simple",
    symbol: {
      color: [	239,138,72,0.4],
      type: "simple-line",
      style: "solid",
      width: 1
    }
}

export const extentOutline = {
  type: "simple",
  symbol:  {
    type: "simple-line", // autocasts as new SimpleFillSymbol()
    color: [232,76,49, 1],
    width: "4px",
    style: "solid"
  }
};

export const polygonHex = {
  type: "simple",
  symbol:  {
    type: "simple-fill", // autocasts as new SimpleFillSymbol()
    color: [232,76,49, 1],
    width: "4px",
    style: "solid"
  }
};

export const hexGridRender = {
  type: "simple",
  symbol: {
      type: "simple-fill",
      color: [
          236,
          194,
          252,
          0
      ],
      outline: {
          type: "simple-line",
          color: [
              110,
              110,
              110,
              255
          ],
          width: 0.7,
          style: "solid"
      },
      style: "solid"
  }
}


export const analyticsRenderer = (t1 = 4, t2 = 2.5, t3 = 1.5, t4 = 1) => {
  return {
    type: "unique-value", 
    field: "highway",

    uniqueValueInfos: [{
      value: 'motorway',
      symbol: analyticsType1(t1)
    },{
      value: "motorway_link",
      symbol: analyticsType1(t1)
    },{
      value: "primary",
      symbol: analyticsType1(t1)
    },{
      value: "primary_link",
      symbol: analyticsType1(t1)
    },{
      value: "trunk",
      symbol: analyticsType1(t1)
    },{
      value: "trunk_link",
      symbol: analyticsType1(t1)
    },{
      value: "secondary",
      symbol: analyticsType2(t2)
    },{
      value: "secondary_link",
      symbol: analyticsType2(t2)
    },{
      value: "tertiary",
      symbol: analyticsType2(t2)
    },{
      value: "unclassified",
      symbol: analyticsType2(t2)
    },{
      value: "living_street",
      symbol: analyticsType3(t3)
    },{
      value: "residential",
      symbol: analyticsType3(t3)
    },{
      value: "motorway_junction",
      symbol: analyticsType3(t3)
    },{
      value: "service",
      symbol: analyticsType4(t4)
    },{
      value: "tertiary_link",
      symbol: analyticsTypeBike(t4)
    },{
      value: "track",
      symbol: analyticsTypeBike(t4)
    },{
      value: "path",
      symbol: analyticsTypeBike(t4)
    },{
      value: "cycleway",
      symbol: analyticsTypeBike(t4)
    },{
      value: "footway",
      symbol: analyticsTypeBike(t4)
    }]}
  };



export let a = new UniqueValueRenderer({
  field: "highway",
  uniqueValueInfos: []

})