import React, { useContext } from "react";
import "../widgets/widgetcss/styles.css";
import { MapContext } from "../../../../MapContext";
import "../../../../Judumas.css";
import { juduLayer } from "../../../../Services/serviceLayers";

export default function DataWidget({ handleStrava, handleSISP, handleOperator }) {
  const mapContext = useContext(MapContext);

  function waitForElm(selector) {
    return new Promise((resolve) => {
      if (document.querySelector(selector)) {
        return resolve(document.querySelectorAll(selector));
      }

      const observer = new MutationObserver((mutations) => {
        if (document.querySelector(selector)) {
          resolve(document.querySelectorAll(selector));
          observer.disconnect();
        }
      });

      observer.observe(document.body, {
        childList: true,
        subtree: true,
      });
    });
  }
  waitForElm(".Data").then((button) => {
    button.forEach((element) => {
      element.addEventListener("click", function () {
        button.forEach((nav) => nav.classList.remove("Active"));
        this.classList.add("Active");
      });
    });
  });

  function changeData(dataContext) {
    juduLayer.visible = false;
    console.log(mapContext.state.data, dataContext)
    
    if (mapContext.state.data == dataContext || dataContext === null || mapContext.state.data.toLowerCase() === dataContext.toLowerCase()) return;

    mapContext.state.featureLayer.visible = false;
    if (mapContext.state.data == "SISP") {
      juduLayer.visible = false;
      mapContext.state.featureLayer.visible = true;
    }
    mapContext.dispatch({
      data: dataContext,
    });
  }

  // Judu layeris tampa problematiskais kai pridedu ji kaip feature layeri, todel ji renderint reikia tiesiai is servisu.
  function changeJudu(dataContext) {
    console.log(mapContext.state.data, dataContext)
    mapContext.state.featureLayer.visible = false;
    juduLayer.visible = true;
    mapContext.dispatch({
      data: dataContext,
    });
    console.log(mapContext.state.data, dataContext)
  }

  return (
    <>
      <div className="DataWidget">
        {"Pasirinkite duomenų šaltinį:"}
        <div className="" style={{ marginLeft: "-1.2vw"}}>
          {mapContext.state.transportation === "Pedestrian" || mapContext.state.transportation === "Bike" || mapContext.state.transportation === "Car" ? (
            <button
              id = "DataActiveVilnius"
              onClick={() => { 
                if (mapContext.state.transportation === "Car")
                {
                  changeJudu(handleSISP);
                } else {
                  changeData(handleStrava);
                }
                
              }}
              className="IconContainer Data"
            >
              {mapContext.state.transportation === "Car" ? "Judu" : "Active Vilnius"}
            </button>
          ): (
            <></>
          )}
            <button
              id = "OperatorNextToLargeText"
              onClick={() => {
                changeData(handleOperator);
              }}
              className="IconContainer Data Active"
            >
              Operatorių
            </button>
        </div>
      </div>
    </>
  );
}
