import React, { useState, useContext, useEffect } from "react";
import { MapContext } from '../../../../MapContext';
import "../../../../Judumas.css"
import Query from "@arcgis/core/rest/support/Query";
import { op_bike_hour, op_bus_hour, op_car_hour, op_pedestrian_hour } from "../../../../Services/serviceLayers";
import { set } from "date-fns";

export default function TimePicker({hour}) {
    const mapContext = useContext(MapContext);  

    const [open, setOpen] = useState(false);
    const [selectedHour, setSelectedHour] = useState(hour);

    const [dateCar, setDateCar] = useState(null);
    const [dateBike, setDateBike] = useState(null);
    const [datePedestrian, setDatePedestrian] = useState(null);
    const [dateBus, setDateBus] = useState(null);

    useEffect(() => {
        let hourSliced = selectedHour.slice(0,-2)
        let date
        
        if (mapContext.state.transportation.toLowerCase() === "car") {
            date = dateCar
        }
        if (mapContext.state.transportation.toLowerCase() === "bike") {
            date = dateBike
        }
        if (mapContext.state.transportation.toLowerCase() === "pedestrian") {
            date = datePedestrian
        }
        if (mapContext.state.transportation.toLowerCase() === "bus") {  
            date = dateBus
        }
        console.log( date)
        mapContext.state.featureLayer.definitionExpression  = "datetime >= timestamp'" + date + " " + selectedHour + ":00' AND datetime <= timestamp'" + date + " " + hourSliced + "59:59'"

        console.log(mapContext.state.featureLayer.definitionExpression)
    },[selectedHour, mapContext.state.breaks])

    useEffect(() => {

        let query = new Query();
        query.where = "1=1";
        query.outFields = ["datetime"];
        query.returnDistinctValues = true;
        query.returnGeometry = false;

        op_bike_hour.queryFeatures(query).then((response) => { 
            let date = new Date(response.features[0].attributes.datetime)
            let day = date.getDate().toString()
            let month = (date.getMonth() + 1).toString()
            let year = date.getFullYear().toString()

            setDateBike(month + "/" + day + "/" + year)
        
        });

        op_car_hour.queryFeatures(query).then((response) => { 
            let date = new Date(response.features[0].attributes.datetime)
            let day = date.getDate().toString()
            let month = (date.getMonth() + 1).toString()
            let year = date.getFullYear().toString()

            setDateCar(month + "/" + day + "/" + year)
        
        });

        op_pedestrian_hour.queryFeatures(query).then((response) => { 
            let date = new Date(response.features[0].attributes.datetime)
            let day = date.getDate().toString()
            let month = (date.getMonth() + 1).toString()
            let year = date.getFullYear().toString()
 
            setDatePedestrian(month + "/" + day + "/" + year)       
        
        });

        op_bus_hour.queryFeatures(query).then((response) => { 
            let date = new Date(response.features[0].attributes.datetime)
            let day = date.getDate().toString()
            let month = (date.getMonth() + 1).toString()
            let year = date.getFullYear().toString()
 
            setDateBus(month + "/" + day + "/" + year)

        });

    },[])



    return (
        <div className="time-picker">
        <button className="time-picker-button" onClick={() => setOpen(!open)}>
            {selectedHour}
        </button>
        {open && (
            <div className="time-picker-dropdown">
            <div className="time-picker-list">
                {Array.from({ length: 24 }, (_, i) => i).map((h) => (
                <button
                    key={h}
                    className={`time-picker-item ${h === selectedHour ? "selected" : ""}`}
                    onClick={() => {
                        h >= 10 ? h = h :  h = "0" + h
                        setSelectedHour(h + ":00")
                        mapContext.dispatch({
                            selectedHour: selectedHour
                        });
                        setOpen(false);
                    }}
                >
                    {h >= 10? h : "0" + h}:00
                </button>
                ))}
            </div>
            </div>
        )}
        </div>
    );
};