import React, { useContext, useEffect } from "react";
import "../widgets/widgetcss/styles.css";
import { MapContext } from "../../../../MapContext";
import "../../../../Judumas.css";

export default function DateWidgetHeatMap({ handleDay, handleMonth, handleYear, handleWeek }) {
  const mapContext = useContext(MapContext);
  const mapFeatureLayer = mapContext.state.featureLayer;

  function waitForElm(selector) {
    return new Promise((resolve) => {
      if (document.querySelector(selector)) {
        return resolve(document.querySelectorAll(selector));
      }

      const observer = new MutationObserver((mutations) => {
        if (document.querySelector(selector)) {
          resolve(document.querySelectorAll(selector));
          observer.disconnect();
        }
      });

      observer.observe(document.body, {
        childList: true,
        subtree: true,
      });
    });
  }

  waitForElm(".Date").then((button) => {
    button.forEach((element) => {
      element.addEventListener("click", function () {
        button.forEach((nav) => nav.classList.remove("Active"));
        this.classList.add("Active");
      });
    });
  });

  useEffect(() => {
    if (mapContext.state.transportation === "Car") {
      waitForElm(".Date").then((button) => {
        button.forEach((element) => {
          element.classList.remove("Active");
        });
      });
      waitForElm("#dayButton").then((button) => {
        button.forEach((element) => {
          element.classList.add("Active");
          handleDateHeatmap("Day");
        });
      });
    }
  }, [mapContext.state.transportation]);

  function handleDateHeatmap(date) {
    console.log(date)
    if ( date === "Hour") {
      mapContext.dispatch({
        timePicker: true,
      });
      
    } else {
      mapContext.dispatch({
        timePicker: false,
      });
    }
    if (mapContext.state.time != date) {
      mapContext.state.featureLayer.visible = false;
      mapContext.dispatch({
        time: date,
      });
     
    }
    return;
  }

  // Changed given args onClick to TimeSwitch
  return (
    <div className="DateWidget">
      <div className="">
        <a>Pasirinkite duomenų laikotarpį:</a>
      </div>
      <br/>
      {(mapContext.state.data.toLowerCase() === "sisp") ? <button className="IconContainer Date Active"  onClick={() => {}}>
      31 d.
      </button>
         :
      <div id="buttonContainer">
          <div id= "circleContainer">
            <button className="IconContainer Active Date" style={{gridColumn: 1,}} id="dayButton" onClick={() => {handleDateHeatmap("Day")}}>
              1 d.
            </button>
            <button className="IconContainer Active Date" style={{gridColumn: 2,}} id="hourButton" onClick={() => {handleDateHeatmap("Hour")}}>
              1 val.
            </button>
          </div>
            <button className="IconContainer Date" style={{gridColumn: 2,}}  onClick={() => {handleDateHeatmap("Week")}}>
              7 d.
            </button>
            <button className="IconContainer Date" style={{gridColumn: 1,}}  onClick={() => {handleDateHeatmap("Month")}}>
              31 d.
            </button>
            <button className="IconContainer Date" style={{gridColumn: 2,}}  onClick={() => {handleDateHeatmap("Year")}}>
              365 d.
            </button>
      </div>
      }
    </div>
  );
}
