import { MapContext } from "../MapContext";
import { useContext } from "react";
import uniqueValues from "@arcgis/core/smartMapping/statistics/uniqueValues";
//https://developers.arcgis.com/javascript/latest/api-reference/esri-rest-support-StatisticDefinition.html

function getMinMax(mapFeatureLayer) {
    //const mapContext = useContext(MapContext);

    let populationChangeDefinition = {
        onStatisticField: "sum_total",  // service field for 2015 population
        outStatisticFieldName: "max_value",
        statisticType: "max"
      }
      let query = mapFeatureLayer.createQuery();
      query.outStatistics = [ populationChangeDefinition ];
      mapFeatureLayer.queryFeatures(query).then(function(response){
          let stats = response.features[0].attributes;
          console.log("Average change:", stats.max_value);
          //MapContext.dispatch({
          //  maxValue: stats.max_value
          //});
          return stats.max_value
    });
}

export async function queryFeaturesOverLimit(mapFeatureLayer, query) {
    //let totalCount = await mapFeatureLayer.queryFeatureCount(query)
    //console.log(totalCount)
    let i = true
    let counter = 0 
    let featuresArr = []
    while (i === true){
        let index =  {num:5000 , start: (counter*5000)}
        let tempQuer = query
        let indexedquery = Object.assign(tempQuer, index)
        let results =  await mapFeatureLayer.queryFeatures(indexedquery)
        
        featuresArr = featuresArr.concat(results.features)
        //console.log(results.features)
        if (results.features.length < 5000) {
            return featuresArr
        } 
        counter++;
    }
}


export async function queryFeaturesOverLimit2k(mapFeatureLayer, query) {
    //let totalCount = await mapFeatureLayer.queryFeatureCount(query)
    //console.log(totalCount)
    let i = true
    let counter = 0 
    let featuresArr = []
    while (i === true){
        let index =  {num:2000 , start: (counter*2000)}
        let tempQuer = query
        let indexedquery = Object.assign(tempQuer, index)
        let results =  await mapFeatureLayer.queryFeatures(indexedquery)
        
        featuresArr = featuresArr.concat(results.features)
        //console.log(results.features)
        if (results.features.length < 2000) {
            return featuresArr
        } 
        counter++;
    }
}


export function toISOLocal(d) {
    //console.log(d)
    if (d === null) {
      return
    }
    var z  = n =>  ('0' + n).slice(-2);
    var zz = n => ('00' + n).slice(-3);
    var off = d.getTimezoneOffset();
    var sign = off > 0? '-' : '+';
    off = Math.abs(off);
  
    return d.getFullYear() + '-'
           + z(d.getMonth()+1) + '-' +
           z(d.getDate()) + 'T' +
           z(d.getHours()) + ':'  + 
           z(d.getMinutes()) + ':' +
           z(d.getSeconds()) + '.' +
           zz(d.getMilliseconds()) +
           sign + z(off/60|0) + ':' + z(off%60); 
}


export function checkOneDayApartAndTransform(inputString) {
    // Task 1: Check if timestamps are one day apart
    function isOneDayApart(str) {
      if (!str) return false; // Handle null input
  
      const dateRegex = /timestamp'(\d{2}\/\d{2}\/\d{4})/g;
      const matches = [...str.matchAll(dateRegex)]; // Extract all date matches
  
      if (matches.length < 2) return false; // Ensure two dates are found
  
      const date1 = new Date(matches[0][1]);
      const date2 = new Date(matches[1][1]);
  
      const diffInDays = Math.abs(date2 - date1) / (1000 * 60 * 60 * 24);
      return diffInDays === 1; // Return true if exactly one day apart
    }
  
    // Task 2: Transform 'datetime' to 'pubmillis'
    function transformString(str) {
      return str ? str.replaceAll('datetime', 'pubmillis') : null;
    }
  
    // Perform both actions and return results
    return {
      isOneDayApart: isOneDayApart(inputString),
      transformedString: transformString(inputString),
    };
  }

  export function transformString(str, orgInput, transformTo) {
    if (!str) return null; // Handle null or undefined input
  
    const regex = new RegExp(orgInput, 'g');
    return str.replace(regex, transformTo);
  }
  
  export function generateDateScoreFilterFromString(dateRangeString) {
    /* Kadangi operatoriu sluoksnyje yra pasikartojanciu gatviu (pvz viena gatve 2023 m. ir ta pati gatvee 2024 m. pasikeite, tai yra atvaizduojama kaip 2 gatves nes yra 2 osm id)
       idetas filtras pagal data atrinkti tinkamas tam laikui gatves, pagal ju score reiksme
    */
    const intervals = [
      // 1 - 79465
      // 2 - 0
      // 3 - 1588
      // 4 - 3212
      // 5 - 857
      // 6 - 0
      // 7 - 136764
      { start: null, end: new Date("2023-11-01"), scores: [1, 3, 7] },
      { start: new Date("2023-11-01"), end: new Date("2024-07-23"), scores: [2, 3, 6, 7] },
      { start: new Date("2024-07-23"), end: null, scores: [4, 6, 7] },
    ];
  
    // Regeksas istraukti datai is mapContext.state.dateRange
    const dateRegex = /datetime\s*>=\s*timestamp'([^']+)' and datetime\s*<\s*timestamp'([^']+)'/;
    const match = dateRangeString.match(dateRegex);
  
    if (!match) {
      throw new Error("Invalid date range string format");
    }
  
    const startDate = new Date(match[1]);
    const endDate = new Date(match[2]);
  
    let scoresToInclude = new Set();
  
    // Jei data prasidetu vienam intervale o pasibaigtu kitam idedami abieju intervalu score
    intervals.forEach(({ start: intervalStart, end: intervalEnd, scores }) => {
      if (
        (intervalStart === null || endDate >= intervalStart) && 
        (intervalEnd === null || startDate < intervalEnd) 
      ) {
        scores.forEach((score) => scoresToInclude.add(score));
      }
    });
  
    // Paduodamas atgal stringas kuri appendinam prie esamo zoom filtro
    const scoreFilter = `score IN (${Array.from(scoresToInclude).join(", ")})`;
  
    return "";
    return ` AND ${scoreFilter}`;
  }

  export function calculatePercentagesFromTotal(numbers, total) {
    console.log(numbers)
    let percentages = numbers.map(num => parseFloat((num / total * 100).toFixed(1)));

    const discrepancy = 100 - percentages.reduce((sum, perc) => sum + perc, 0);

    if (discrepancy !== 0) {
        const indexToAdjust = percentages.findIndex(num => num === Math.max(...percentages));
        percentages[indexToAdjust] += discrepancy;
    }

    return percentages;
}

export function transoprationAvailableDatesParam(mapContext) {   
  if (mapContext.state.transportation.toLowerCase() ===  "car") {
    return "date_str"
  }
  else if (mapContext.state.transportation.toLowerCase() ===  "bus") {
    return "dates_vt"
  }
  else if (mapContext.state.transportation.toLowerCase() ===  "pedestrian") {
    return "dates_pedestrians"
  }
  else if (mapContext.state.transportation.toLowerCase() ===  "bike") {
    return "dates_bikes"
  } else {
    return "date_str"
  }
}

export async function availableDatesQuery(mapContext, operatorAvailableDates) {
    let dateParam = transoprationAvailableDatesParam(mapContext)

      uniqueValues({
        layer: operatorAvailableDates,
        field: dateParam,
      }).then(function (response) {
        // prints each unique value and the count of features containing that value
        let infos = response.uniqueValueInfos;
        let availableDatesLocal = [];
        let parsedDates = [];
        infos.forEach(function (info) {

          if (!availableDatesLocal.includes(info.value) && info.value != null) {
            //let addDate = new Date(info.value)
            //console.log(date)
            let splitDate = info.value.split('/')
            let addDate = new Date(splitDate[0], splitDate[1] - 1, splitDate[2]);
            parsedDates.push(addDate);
            availableDatesLocal.push(info.value);
            //setAvailableDates(availableDatesLocal)
            //console.log(availableDatesLocal)
          }
        });
        console.log("parsedDates")
        console.log(parsedDates)
        return parsedDates
        
      });

}
  

  